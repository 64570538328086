<template>
  <div class="card" style="min-height: calc(100vh - 180px)">
    <div class="bg-blue-light p-1">
      <TitleButton
          btnTitle="Add New"
          :showBtn="false"
          :showAddNew="false"
          :title="'Pending - Project Kick-off' + (fromApproval ? ' (Approval)' : '')"
          @onClickAddNewButton="navigateToAddPage"
      />
    </div>

    <div class="my-2 px-2">
      <DateQuerySetter
          btnTitle="Go"
          @onClickGo="onClickGo"
      >
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <div class="d-flex align-items-center justify-content-between filter-label">
            <label for="colFormLabel" class="col-form-label">Client: </label>
            <span class="filter-search-icon cursor-pointer" @click="onOpenContactSearchModal">
                  <i class="fas fa-search"></i>
              </span>
          </div>

          <AsyncSelect
              placeholder="Select Party"
              v-model="contactProfile"
              :api-service="fetchContactProfiles"
              :additional-query="additionalContactOption"
              :additional-option="additionalOption"
              :key="contactRenderKey"
          />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <div class="d-flex align-items-center justify-content-between filter-label">
            <label for="colFormLabel" class="col-form-label">Contact Owner: </label>
            <span class="filter-search-icon cursor-pointer" @click="onOpenContactSearchModal()">
                  <i class="fas fa-search"></i>
              </span>
          </div>
          <AsyncSelect
              placeholder="Select Contact Owner"
              v-model="contactOwner"
              :api-service="fetchContactProfiles"
              :additional-query="{ with_mobile: 1, roles: 'contact_owner' }"
              :format-label="formatPatientLabel"
          />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <div class="d-flex align-items-center justify-content-between filter-label">
            <label for="colFormLabel" class="col-form-label">Key Account Manager: </label>
            <span class="filter-search-icon cursor-pointer" @click="onOpenContactSearchModal()">
                  <i class="fas fa-search"></i>
              </span>
          </div>
          <AsyncSelect
              placeholder="Select Key Account Manager"
              v-model="keyAccountManager"
              :api-service="fetchContactProfiles"
              :additional-query="{ with_mobile: 1, roles: 'key_account_manager' }"
              :format-label="formatPatientLabel"
          />
        </div>
      </DateQuerySetter>

    </div>
    <div class="col-12 px-2 mt-2">
      <ListTable
          :lists="projectInitiateList"
          route_name="sales-order-edit"
          :fromApproval="fromApproval"
      />
    </div>
    <div class="px-2">
      <Pagination :offset="offset" @onPageChange="onPageChange" ref="pagination"/>
    </div>

    <ContactDropdownFilter
        v-if="store.state.isModalOpenThree"
        @onSearchContact="onSearchContact"
        :companyRoles="companyRoles"
        :isFilterable="true"
    />
  </div>
</template>

<script setup>
import DateQuerySetter from '@/components/atom/DateQuerySetter'
import {ref, inject, onMounted, computed, watch} from 'vue'
import {useStore} from 'vuex'
import {useRouter, useRoute} from 'vue-router'
import handlePurchase from '@/services/modules/purchase'
import TitleButton from '@/components/atom/TitleButton'
import Pagination from '@/components/atom/Pagination'
import ListTable from '@/components/molecule/company/inventory/project-initiate/ListTable.vue'
import handleContact from "@/services/modules/contact";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import handleRole from "@/services/modules/role";
import ContactDropdownFilter from '@/components/atom/Contact/ContactDropdownFilter.vue'
import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";
const {formatPatientLabel} = useAsyncDropdownHelper();

const router = useRouter()
const store = useStore()
let offset = ref(20)
let projectInitiateList = ref([])
let contactProfile = ref(null)
let contactOwner = ref(null)
let keyAccountManager = ref(null)
const route = useRoute()
const singleSR = ref(null);
const singleDealer = ref(null);
const dealerRenderKey = ref(0);
const contactRenderKey = ref(0);

const {fetchPendingProductInitiateList, fetchOrderStatus} = handlePurchase()
const {fetchContactProfiles} = handleContact();
const {fetchCompanyDefaultRoles} = handleRole();
const additionalOption = ref([]);
const additionalDealerQuery = ref({});
const additionalContactOption = ref({
  with_mobile: 1,
  dealer_id: '',
  roles: 'customer,lead'
});
const companyRoles = ref(null);
const invoiceStatuses = ref([]);
const orderStatuses = ref([]);
const selectedInvoiceStatus = ref(null);
const selectedOrderStatus = ref(null);

const getCompanyRoles = async () => {
  const res = await fetchCompanyDefaultRoles(`?company_id=${companyId.value}&roles=customer,lead`);
  if (res.status) {
    companyRoles.value = res.data
  }
}

const onOpenContactSearchModal = () => {
  store.state.isModalOpenThree = true;
}

const onSearchContact = (contact) => {
  contactProfile.value = contact
}

const companyId = computed(() => {
  return route.params.companyId
})
const startDate = computed(() => {
  return route.query.start
})
const endDate = computed(() => {
  return route.query.end
})
const page = computed(() => {
  return route.query.page
})
const fromApproval = computed(() => {
  return route.name === 'sales-order-approval';
})

watch(() => singleSR.value, (newValue, oldValue) => {
  additionalDealerQuery.value = {};
  additionalContactOption.value.dealer_id = '';
  singleDealer.value = null;
  contactProfile.value = null;
  dealerRenderKey.value += 1;
  contactRenderKey.value += 1;

  if (newValue) {
    additionalDealerQuery.value = {'sale_representative_id': newValue.id}
  }
})

watch(() => singleDealer.value, (newValue, oldValue) => {
  additionalContactOption.value.dealer_id = '';
  contactProfile.value = null;
  contactRenderKey.value += 1;

  if (newValue) {
    additionalContactOption.value.dealer_id = newValue.id
  }
})

function navigateToAddPage() {
  const query = Object.assign({}, route.query)
  query.type = 'approval';

  router.push({name: 'sales-order-add', params: route.params, query: query})
}

function getQuery() {
  let query = '?company_id=' + companyId.value
  if (!startDate.value && !endDate.value) return query
  if (contactProfile.value) query += '&contact_profile_id=' + contactProfile.value.id
  if (contactOwner.value) query += '&contact_owner_id=' + contactOwner.value.id
  if (keyAccountManager.value) query += '&key_account_manager_id=' + keyAccountManager.value.id
  if (startDate.value) query += '&start_date=' + startDate.value
  if (endDate.value) query += '&end_date=' + endDate.value
  if (page.value) query += '&page=' + page.value
  if (selectedOrderStatus.value) query += '&status=' + selectedOrderStatus.value
  if (selectedInvoiceStatus.value) query += '&invoice_status=' + selectedInvoiceStatus.value
  query += '&offset=' + offset.value
  return query
}

function onClickGo() {
  fetchPendingProductInitiateList(getQuery())
      .then(res => {
        if (res.status) {
          projectInitiateList.value = res.date.data
          setPagination(res.date)
        } else {
          projectInitiateList.value = []
          store.commit("resetPagination")
        }

      })
}

const getOrderStatus = async () => {
  const res = await fetchOrderStatus('?company_id=' + companyId.value);
  if (res.status) {
    invoiceStatuses.value = res.data.filter(item => ['created', 'pending'].includes(item.value))
    orderStatuses.value = res.data.filter(item => ['pending', 'approved'].includes(item.value))
  }
}

function setPagination(data) {
  if (!data.data.length) return
  store.commit('setPaginationData', {
    records: data.total,
    perPage: data.per_page,
    currentPage: data.current_page,
  })
}

function onPageChange(page) {
  let routeQuery = Object.assign({}, route.query)
  routeQuery.page = page
  router.push({path: route.path, query: routeQuery})
  onClickGo()
}

onMounted(() => {
  onClickGo()
  getOrderStatus()
  getCompanyRoles()
})

</script>

<style scoped>
.mt-tpp-1 {
  margin-top: 1%;
}

.filter-label {
  padding-right: 0.5rem !important;
}
</style>
