<template>
  <div class="modal fade show modal-style" aria-labelledby="addNewCardTitle">
    <div class="modal-dialog modal-dialog-centered modal-lg" ref="modalRef">
      <div class="modal-content">
        <div class="row">
          <div class="col-12">
            <div class="d-flex justify-content-between px-1 my-1 align-item-center">
              <h1 class="mb-0">Select Item for Project Kickoff</h1>
              <button
                  @click="onClickCloseModal"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
              ></button>
            </div>
          </div>
        </div>

        <hr class="mt-0 mb-0">

        <div class="pb-2" v-if="selectedItem">
          <div class="row py-1 mx-auto p-10">
            <table class="table table-bordered">
              <thead>
              <tr>
                <th>INVOICE TO: {{ selectedItem.contact.name }}</th>
                <th>Bill Number: {{ selectedItem.bill_number }}</th>
                <th>DATE: {{ selectedItem.date }}</th>
              </tr>
              </thead>
            </table>
            <hr>
            <table class="table table-bordered">
              <thead>
              <tr>
                <th>
                  <input type="checkbox" @click="selectAll" v-model="checkAll">
                </th>
                <th>PARTICULARS</th>
                <th>QTY</th>
                <th>RATE</th>
                <th>AMOUNT</th>
              </tr>
              </thead>
              <tbody>
              <template v-for="item in selectedItem.general">
                <tr v-if="!parseIgnoreGenerals(item.id)">
                  <td>
                    <input :checked="selected.includes(item.id)" @input="check(item.id)" type="checkbox">
                  </td>
                  <td>{{ item.product.name }}</td>
                  <td>{{ item.quantity }}</td>
                  <td>{{ item.rate }}</td>
                  <td>{{ item.amount }}</td>
                </tr>
              </template>
              </tbody>
            </table>
            <div class="col-4 d-flex align-items-center mt-1">
              <button :disabled="selected.length == 0" @click="onClickToGo"
                      class="btn btn-primary btn-block waves-effect waves-float waves-light">
                Go
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

import {ref} from "vue";
import {useRoute, useRouter} from "vue-router";

const props = defineProps({
  onClickClose: Function,
  selectedItem: {
    type: Object,
    default: {}
  },
  ignoreGenerals: {
    type: String,
    default: ''
  }
})
const route = useRoute()
const router = useRouter()
const emit = defineEmits(['onClickClose']);
const selected = ref([])
const checkAll = ref(false);

const onClickCloseModal = () => {
  emit('onClickClose')
}

function convertToArray(str) {
  const cleanedStr = str.replace(/\[|\]|,/g, '');
  const array = cleanedStr.split('"').filter(Boolean);
  return array.map(Number);
}

const parseIgnoreGenerals = (id) => {
  if (props.ignoreGenerals == null) {
    return false
  }
  const ignoreGenerals = convertToArray(props.ignoreGenerals);
  return ignoreGenerals.includes(Number(id));
};

const onClickToGo = () => {
  if (selected.value.length == 0) {
    return alert('Please item select first!')
  }
  let params = route.params;
  params.soId = props.selectedItem.id;

  let query = route.query;
  query.seletedItems = selected.value;

  router.push({name: 'create-project-initiate', params: params, query: query})
}

const selectAll = () => {
  selected.value = [];
  if (!checkAll.value) {
    props.selectedItem.general.forEach((item) => {
      selected.value.push(item.id);
    });
  }
}

const check = (id) => {
  if (selected.value.includes(id)) {
    selected.value.splice(selected.value.indexOf(id), 1);
  } else {
    selected.value.push(id);
  }

  checkAll.value = selected.value.length === props.selectedItem.general.length;
}
</script>

<style scoped>
.modal-style {
  display: block;
  background: #1c1d1c33
}
</style>
