<template>
  <div class="card" style="min-height: 200px">
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th>Client</th>
          <th>Order Number</th>
          <th>Order Date</th>
          <th class="text-right">Order value</th>
          <th>Client Contact Person</th>
          <th>Contact Number</th>
          <th>Contact Owner</th>
          <th>Key Account Manager</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in lists" :key="i">
          <td> {{ item.party_name }}</td>
          <td>
            <button class="btn badge bg-dark bg-opacity-75 bg-primary btn-sm">
              <span>{{ item.bill_number }}</span>
            </button>
          </td>
          <td> {{ item.date }}</td>
          <td class="text-right"> {{ item.bill_amount }}</td>
          <td>
            <span v-for="contact_person in getList(item.cp_contact_persons)"
                  class="badge badge bg-dark bg-opacity-75 bg-primary">{{ contact_person }}</span>
          </td>
          <td>
            <span v-for="phone in getList(item.cp_phones)" class="badge badge bg-dark bg-opacity-75 bg-primary">{{
                phone
              }}</span>
          </td>
          <td>{{ item.contact_owner_name }}</td>
          <td>{{ item.key_account_manager_name }}</td>
          <td>
            <button v-if="item.general_count > getBookedLength(item.booked_sale_generals)" @click="openSelectItemModal(item)"
                    class="btn btn-primary btn-sm mr-5">
              <i class="fas fa-plus"></i>
              Start Project
            </button>
            <span class="badge badge bg-dark bg-opacity-75 bg-primary" v-for="project_master_id in JSON.parse(item.project_master_ids)"
                  @click="handleEdit(item.id, project_master_id)" style="cursor: pointer">
            {{ project_master_id }} Edit
          </span>
          </td>
        </tr>

        </tbody>
      </table>
      <p v-if="!lists.length" class="text-center mt-5">No data</p>
    </div>
  </div>
  <ItemSelectModal
      v-if="itemSelectModal"
      :ignoreGenerals="ignoreGenerals"
      :selectedItem="selectedItem"
      @onClickClose="onClickClose"
  />
</template>

<script setup>
import {ref} from "vue";
import ItemSelectModal from '@/components/molecule/company/inventory/project-initiate/ItemSelectModal.vue'
import handlePurchase from "@/services/modules/purchase";
import {useRoute, useRouter} from "vue-router";

const {fetchSalesOrderById} = handlePurchase();
const route = useRoute()
const router = useRouter()
const props = defineProps({
  lists: Array,
})

const getList = (items) => {
  if (!items) {
    return []
  }
  return items.split(',').map(item => item.trim());
}


const itemSelectModal = ref(false);
const selectedItem = ref({});
const ignoreGenerals = ref('');
const openSelectItemModal = (item) => {
  ignoreGenerals.value = ''
  const companyQuery = `?company_id=${route.params.companyId}`;

  fetchSalesOrderById(item.id, companyQuery).then((res) => {
    if (!res.data) return;

    selectedItem.value = res.data
    ignoreGenerals.value = item.booked_sale_generals
    itemSelectModal.value = true
  }).catch((err) => {
    console.log("Something went wrong");
  })
}

const onClickClose = () => {
  itemSelectModal.value = false
}
const handleEdit = (soId, projectId) => {
  route.params.soId = soId;
  route.params.projectId = projectId;
  router.push({name: `edit-project-initiate`, params: route.params, query: route.query});
}

const getBookedLength = (items) => {
  if (items == null) {
    return 0
  }
  const result = convertToArray(items);
  return result.length;
}

const convertToArray = str => {
  const cleanedStr = str.replace(/^\[\[|\]\]$/g, '');
  const subArrays = cleanedStr.split(/\],\s*\[/).map(s => s.replace(/\[|\]|"|,/g, ''));
  return subArrays.flatMap(subArray => subArray.split(' ').filter(Boolean).map(Number));
}
</script>
